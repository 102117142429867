import React, { useMemo, useState } from 'react';
import { Profile } from '../../../../../domain';
import { useQuery } from '@tanstack/react-query';
import { API } from '../../../../apis';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { ReportedContent } from './ReportedContentDomain';
import _ from 'lodash';
import classNames from 'classnames';
import styles from './ReportedContent.module.scss';
import { DataTableHelper, DateHelper, HistoryHelper } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { ReportedContentDetails } from './ReportedContentDetails';
import { DailyDatePicker } from '../../../../components';
import Toggle from 'react-toggle';


interface Props {
  magazine: number,
  profile: Profile,
}

export const ReportedContentModule = ({ magazine, profile }: Props) => {

  const [fromDate, setFromDate] = useState<string>();
  const [untilDate, setUntilDate] = useState<string>();
  const [showOnlyPending, setShowOnlyPending] = useState<boolean>(true);

  const history = useHistory();

  const reportedContentId = HistoryHelper.getQueryParamNumber(history, 'reportedContent');


  const reportedContentsQuery = useQuery({
    queryKey: ['reportedContents', magazine, fromDate, untilDate],
    queryFn: async () => {
      try {
        const { data } = await API.reportedContent.getReportedContents(magazine, fromDate || undefined, untilDate || undefined);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting reported contents`);
      }
    }
  });

  const reportedContentsToShow: ReportedContent[] = useMemo(() => {
    if (!reportedContentsQuery.data) {
      return [];
    }
    if (!showOnlyPending) {
      return reportedContentsQuery.data;
    }
    return _.filter(reportedContentsQuery.data, (r) => r.reviewedReports !== r.allReports)
  }, [reportedContentsQuery.data, showOnlyPending]);

  const isLoading = reportedContentsQuery.isFetching && reportedContentId === undefined;

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'articleId',
      hidden: true,
      text: 'Id',
    },
    {
      dataField: 'articleStatus',
      text: '',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '50px' },
      headerFormatter: (column, colIndex) => {
        return <><img data-tippy-content={'Status'} src={'/assets/icons/16/stats.svg'} /><span></span></>
      },
      formatter: (cell: number, row: ReportedContent) => {
        try {
          const status = cell ? 'visible' :
            row.reviewedReports === row.allReports ?
              'rejected'
              :
              'hidden';
          const tippyContent = _.capitalize(status);

          return <i className={`fa fa-circle status status-${status}`} data-tippy-content={tippyContent}></i>;

        } catch (error) {
          return <>{cell}</>
        }

      },
    },
    {
      dataField: 'articleTitle',
      text: 'Article/post',
    },
    {
      dataField: 'articleReports',
      sort: true,
      text: 'Reports',
      headerStyle: { width: '100px' },
    },
    {
      dataField: 'reportedComments',
      sort: true,
      text: 'Reported Comments',
      headerStyle: { width: '150px' },
    },
    {
      dataField: 'latestReport',
      sort: true,
      text: 'Latest Report',
      headerStyle: { width: '150px' },
      formatter: DataTableHelper.dateFormatter
    },
    {
      dataField: 'allReports',
      sort: true,
      text: 'Reviewed Reports',
      headerStyle: { width: '150px' },
      formatter: (cell: number, row: ReportedContent) => {
        return (
          <>
            <span>
              {row.reviewedReports}/{row.allReports}
            </span>
            <i className={classNames('fa', 'fa-check', styles.allReviewedIcon, { [styles.visible]: row.reviewedReports === row.allReports, [styles.rejected]: row.articleStatus ? false : true })}></i>
          </>
        )
      }
    },
  ];

  const onOpenReportedContentDetails = (reportedContent: number) => {
    HistoryHelper.setQueryParams(history, { reportedContent });
  }

  const onCloseReportedContentDetails = () => {
    HistoryHelper.setQueryParams(history, { reportedContent: undefined });
  }

  return (
    <div className={classNames(styles.ReportedContent)} >
      {isLoading && <OrtecLoader />}

      <DataTable
        data={reportedContentsToShow}
        columns={columns}
        keyField={'articleId'}
        defaultSorted={[{
          dataField: 'latestReport',
          order: 'asc'
        }]}
        defaultSizePerPage={25}
        onRowClick={(row: ReportedContent) => { onOpenReportedContentDetails(row.articleId) }}
        secondaryRow={
          <div className={styles.dateFiltersContainer}>
            <div className={styles.pendingReportsToggleWrapper}>
              <Toggle checked={showOnlyPending} onChange={(e) => setShowOnlyPending(e.target.checked)} /> <span onClick={() => setShowOnlyPending((oldVal) => !oldVal)}>Show only with pending reviews</span>
            </div>
            <DailyDatePicker
              period={fromDate || ''}
              changePeriod={setFromDate}
              maxPeriod={untilDate}
              popperCustomClassName={styles.datePickerPopper}
            >
            </DailyDatePicker>
            <DailyDatePicker
              period={untilDate || ''}
              changePeriod={setUntilDate}
              minPeriod={fromDate}
              maxPeriod={DateHelper.todayAsString()}
              popperCustomClassName={styles.datePickerPopper}
            >
            </DailyDatePicker>
          </div>
        }
        toolbarStyle={{ display: 'none' }}
      />
      {reportedContentId &&
        <ReportedContentDetails
          magazine={magazine}
          profile={profile}
          articleId={reportedContentId}
          onClose={onCloseReportedContentDetails}
        />
      }

    </div >
  )
}
