import React, { useEffect, useMemo, useState } from 'react';
import styles from './ReportedContentDetails.module.scss';
import { FullPageModal, FullPageModalContent, FullPageModalContentPart, FullPageModalHeader } from '../../../../components/FullPageModal';
import { generateUniversalLink } from '../../../../utils/genericHelper';
import { Profile } from '../../../../../domain';
import { ArticleDiscussion, QrModal } from '../../../../components';
import ArticlePreview from '../../../../components/ArticlePreview/ArticlePreview';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../../apis';
import { EdErrorHandler, LoadingButton, Swal } from '../../../../widgets';
import { AccordionItem } from '../../../../components/Accordion/AccordionItem';
import { FlaggedContent } from './ReportedContentDomain';
import { ReportedContentReports } from './ReportedContentReports';
import classNames from 'classnames';
import Toggle from 'react-toggle';
import { Comment } from '../comments/domain';


interface Props {
  magazine: number,
  profile: Profile,
  articleId: number,
  onClose: () => void,
}

export const ReportedContentDetails = ({ magazine, profile, articleId, onClose }: Props) => {

  const [qrModal, setQrModal] = useState<boolean>(false);

  const [isCommentsAccordionOpen, setIsCommentsAccordionOpen] = useState<boolean>(true);
  const [isReportsOnCommentAccordionOpen, setReportsOnCommentAccordionOpen] = useState<boolean>(true);
  const [isReportsOnPostAccordionOpen, setReportsOnPostAccordionOpen] = useState<boolean>(true);

  const [activeCommentId, setActiveCommentId] = useState<number>();
  const [activeComment, setActiveComment] = useState<Comment>();

  const toggleCommentsAccordion = () => {
    setIsCommentsAccordionOpen(!isCommentsAccordionOpen);
  }

  const toggleReportsOnCommentAccordion = () => {
    setReportsOnCommentAccordionOpen(!isReportsOnCommentAccordionOpen);
  }

  const toggleReportsOnPostAccordion = () => {
    setReportsOnPostAccordionOpen(!isReportsOnPostAccordionOpen);
  }


  const universalLink = generateUniversalLink(profile.appMeta, `/a/${articleId}`);

  const articleDetailsQuery = useQuery({
    queryKey: ['articleDetails', magazine, articleId],
    queryFn: async () => {
      try {
        const { data } = await API.articles.getArticleDetails(magazine, articleId);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting article details`);
      }
    }
  });

  const reportedContentQuery = useQuery({
    queryKey: ['reportedContent', magazine, articleId],
    queryFn: async () => {
      try {
        const { data } = await API.reportedContent.getReportedContent(magazine, articleId);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting reported content in article`);
      }
    }
  });

  const queryClient = useQueryClient();


  const markAllAsReviewedMutation = useMutation({
    mutationKey: ['reviewAllReports', magazine, articleId],
    mutationFn: (articleId: number) => API.reportedContent.markAllReportsAsReviewed(magazine, articleId),
    onError: (error) => {
      EdErrorHandler(error, `marking all reports as reviewed`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reportedContent', magazine, articleId] });
      queryClient.invalidateQueries(['reportedContents', magazine]);
    }
  });

  const rejectPostMutation = useMutation({
    mutationKey: ['rejectPost', magazine, articleId],
    mutationFn: (articleId: number) => API.reportedContent.rejectPost(magazine, articleId),
    onError: (error) => {
      EdErrorHandler(error, `rejecting post`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reportedContent', magazine, articleId] });
      queryClient.invalidateQueries(['reportedContents', magazine]);
      queryClient.invalidateQueries(['articleDetails', magazine, articleId]);
    }
  });

  const reenablePostMutation = useMutation({
    mutationKey: ['rejectPost', magazine, articleId],
    mutationFn: (articleId: number) => API.reportedContent.reenablePost(magazine, articleId),
    onError: (error) => {
      EdErrorHandler(error, `reenabling post`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reportedContent', magazine, articleId] });
      queryClient.invalidateQueries(['reportedContents', magazine]);
      queryClient.invalidateQueries(['articleDetails', magazine, articleId]);
    }
  });

  const onMarkAllAsReviewed = () => {
    markAllAsReviewedMutation.mutate(articleId);
  }

  useEffect(() => {
    if (!reportedContentQuery.data || activeCommentId !== undefined) {
      return;
    }

    const firstReportedComment = _.find(reportedContentQuery.data, (r) => r.commentId !== undefined);
    setActiveCommentId(firstReportedComment?.commentId);


  }, [reportedContentQuery.data, activeCommentId])


  const reportsOnPost: FlaggedContent[] = useMemo(() => {
    if (!reportedContentQuery.data) {
      return [];
    }
    return _.filter(reportedContentQuery.data, (r) => r.contentType === 'article')
  }, [reportedContentQuery.data]);

  const hasUnreviewedReports: boolean = useMemo(() => {
    if (!reportedContentQuery.data) {
      return false;
    }
    return _.find(reportedContentQuery.data, (r) => r.status === 'new') ? true : false;
  }, [reportedContentQuery.data])

  const hasReportsOnPost = !_.isEmpty(reportsOnPost);
  const totalReviewedReportsOnPost = _.size(_.filter(reportsOnPost, (r) => r.status !== 'new'));
  const totalReportsOnPost = _.size(reportsOnPost);

  const reportsOnComments: FlaggedContent[] = useMemo(() => {
    if (!reportedContentQuery.data) {
      return [];
    }
    return _.filter(reportedContentQuery.data, (r) => r.contentType === 'comment')
  }, [reportedContentQuery.data]);

  const reportedComments: number = useMemo(() => {
    if (!reportedContentQuery.data) {
      return 0;
    }
    return _.size( //count unique commentIds
      _.uniq( //keep the uniq ones
        _.compact(  //remove null
          _.map(reportedContentQuery.data, (r) => r.commentId) //all commentIds
        )
      )
    );
  }, [reportedContentQuery.data]);

  const reportsOnSelectedComment: FlaggedContent[] = useMemo(() => {
    if (_.isEmpty(reportsOnComments) || !activeCommentId) {
      return [];
    }
    return _.filter(reportsOnComments, (r) => r.commentId === activeCommentId)
  }, [reportsOnComments, activeCommentId]);

  const hasReportsOnSelectedComment = !_.isEmpty(reportsOnSelectedComment);

  const openQRModal = () => {
    setQrModal(true);
  }

  const closeQRModal = () => {
    setQrModal(false);
  }

  const reenablePost = () => {
    reenablePostMutation.mutate(articleId);
  }

  const rejectPost = () => {
    rejectPostMutation.mutate(articleId);
  }

  const onToggleArticleVisibility = async (checked: boolean) => {

    const confirmationText = checked ?
      `This will re-enable the post but you will need to review all the reports again.` :
      `This will mark all existing reports both on the post and on its comments as reviewed and it will hide this post.`;

    const { value: confirm } = await Swal.fire({
      title: 'Are you sure?',
      type: 'warning',
      text: confirmationText,
      showCancelButton: true,
      confirmButtonText: `Yes, ${checked ? 're-enable' : 'hide'} it!`,
      focusCancel: true,
    });
    if (!confirm) {
      return;
    }

    if (checked) {
      reenablePost();
    } else {
      rejectPost();
    }
  }

  const isLoading = articleDetailsQuery.isFetching || reenablePostMutation.isLoading || rejectPostMutation.isLoading;
  const mutationLoading = markAllAsReviewedMutation.isLoading || (markAllAsReviewedMutation.isSuccess && queryClient.isFetching({ queryKey: ['reportedContent', magazine, articleId] })) ? true : false;

  return (
    <div className={styles.ReportedContentDetails}>
      <FullPageModal >
        <FullPageModalHeader isLoading={isLoading} onClose={onClose}>
          <div className={styles.idContainer}>
            <div className={styles.headerLabel}>ARTICLE ID</div>
            <div className={styles.headerVal}> {articleId}</div>
          </div>
          <div className={styles.linkContainer}>
            <div className={styles.headerLabel}>UNIVERSAL LINK</div>
            <div className={styles.headerVal}> <a href={universalLink} target={'_blank'}>{universalLink}</a></div>
          </div>
          <div className={styles.flexSpacer}></div>
          <div className={styles.qrContainer}>
            <i className={`fa fa-qrcode qrToggler`} data-tippy-content={'Click to see QR code'} onClick={() => { openQRModal() }}></i>
          </div>
        </FullPageModalHeader>
        <FullPageModalContent isLoading={isLoading}>
          <FullPageModalContentPart flex={1} cssStyle={{ display: 'flex', flexDirection: 'column' }} mainPart>
            <div className={classNames(styles.articlePreviewWrapper)}>
              <div className={classNames(styles.articlePreviewContainer, { [styles.attentionBorder]: totalReviewedReportsOnPost < totalReportsOnPost })}>
                <ArticlePreview magazine={_.toString(magazine)} article={{ ...articleDetailsQuery.data, id: _.toString(articleId) }} defaultVariationLanguage={profile.magazineLanguages?.primary} showUpdateDate />
              </div>
              <div className={styles.postReportsToolbar}>
                {hasUnreviewedReports &&
                  <LoadingButton color={'secondary'} loading={mutationLoading} text={'mark all as reviewed'} onClick={onMarkAllAsReviewed} />
                }
                <div className={styles.articleVisibleToggleWrapper}>
                  <Toggle checked={articleDetailsQuery.data?.status ? true : false} onChange={(e) => { onToggleArticleVisibility(e.target.checked) }} />
                  <span>Visible</span>
                </div>

                <div style={{ flex: 1 }}></div>
                {hasReportsOnPost &&
                  <div className={classNames(styles.postReportsIconWrapper, { [styles.attention]: totalReviewedReportsOnPost < totalReportsOnPost })}>
                    <span className={'material-icons'}>flag</span>
                    <span>{totalReviewedReportsOnPost}/{totalReportsOnPost}</span>
                  </div>
                }
              </div>
            </div>
            {hasReportsOnPost &&
              <AccordionItem
                isOpen={isReportsOnPostAccordionOpen}
                toggle={toggleReportsOnPostAccordion}
                title={`reports on post (${reportsOnPost.length})`}
              >
                <div className={styles.reportsInPostWrapper}>
                  <ReportedContentReports
                    magazine={magazine}
                    reports={reportsOnPost}
                    compareWithDate={articleDetailsQuery.data?.mdate}
                  />
                </div>
              </AccordionItem>
            }
          </FullPageModalContentPart>
          <FullPageModalContentPart cssStyle={{ overflowY: 'auto', minWidth: '250px' }} withBackground>
            <AccordionItem
              isOpen={isCommentsAccordionOpen}
              toggle={toggleCommentsAccordion}
              title={`comments (${reportedComments} reported)`}
            >
              <div className={styles.articleDiscussionWrapper}>
                <ArticleDiscussion
                  magazine={magazine}
                  articleId={articleId}
                  activeCommentId={_.toString(activeCommentId)}
                  reportedContent={reportsOnComments}
                  changeActiveCommentId={setActiveCommentId}
                  setActiveComment={setActiveComment}
                  helpText={'Select the comments you want to delete'}
                  helpTextClass={styles.sectionHelpText}
                  helpTextNotFound={'No comments found for this article'}
                />
              </div>
            </AccordionItem>
            {hasReportsOnSelectedComment &&
              <AccordionItem
                isOpen={isReportsOnCommentAccordionOpen}
                toggle={toggleReportsOnCommentAccordion}
                title={`reports on selected comment (${_.size(reportsOnSelectedComment)})`}
              >
                <div className={styles.reportsIncCommentsWrapper}>
                  <ReportedContentReports
                    magazine={magazine}
                    reports={reportsOnSelectedComment}
                    compareWithDate={activeComment?.mdate}
                    reportedComment={activeComment}
                  />
                </div>
              </AccordionItem>
            }
          </FullPageModalContentPart>
        </FullPageModalContent>
      </FullPageModal>
      {qrModal && <QrModal universalLink={universalLink} closeModal={closeQRModal} type={'article'} noVisibleLink />}
    </div>
  )
}
