import React, { useEffect, useState } from 'react';
import { API } from '../../apis';
import { EdErrorHandler, OrtecLoader, SwalDelete } from '../../widgets';
import { Comment } from '../../pages/Content/subpages/comments/domain';
import styles from './ArticleDiscussion.module.scss';
import { ArticleDiscussionComment } from './ArticleDiscussionComment';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { FlaggedContent } from '../../pages/Content/subpages/reportedContent/ReportedContentDomain';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  magazine: number,
  articleId: number,
  activeCommentId?: string,
  helpText?: string,
  helpTextClass?: string,
  helpTextNotFound?: string,
  readOnly?: boolean
  changeActiveCommentId?: (commentId: number) => void,
  reportedContent?: FlaggedContent[]
  setActiveComment?: (comment?: Comment) => void
}

export const ArticleDiscussion = ({ magazine, articleId, activeCommentId, helpText, helpTextClass, helpTextNotFound, readOnly, changeActiveCommentId, reportedContent, setActiveComment }: Props) => {

  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [noCommentsFound, setNoCommentsFound] = useState<boolean>(false);

  const [selected, setSelected] = useState<string[]>([]);

  const queryClient = useQueryClient();


  useEffect(() => {
    loadArticleComments();
  }, [articleId]);

  useEffect(() => {
    if (!_.isEmpty(comments) && activeCommentId && setActiveComment) {
      const activeComment = _.find(comments, (c) => c.id === activeCommentId);

      setActiveComment(activeComment);
    }
  }, [activeCommentId, comments, setActiveComment])



  const loadArticleComments = async () => {
    try {
      setLoading(true);
      const { data } = await API.comments.getCommentsOfArticle(magazine, articleId);
      setComments(data);
      if (data.length == 0) {
        setNoCommentsFound(true);
      }
    } catch (error) {
      EdErrorHandler(error, `loading article comments.`)
    } finally {
      setLoading(false);
    }
  }

  const onCommentToggle = (commentId: string, checked: boolean) => {
    if (readOnly) {
      return;
    }
    const commentIds = _.map(_.filter(comments, (c) => c.id == commentId || c.parentId == _.toNumber(commentId)), (c) => c.id);

    if (checked) {
      setSelected(_.uniq([...selected, ...commentIds]))
    } else {
      setSelected(_.filter(selected, (s) => !_.includes(commentIds, s)));
    }
  }

  const onDeleteSelectedComments = async () => {
    const { length } = selected;
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Yes, delete ${length > 1 ? 'them' : 'it'}!`,
      focusCancel: true,
      html: `This action will permanently delete all ${length} selected comment${length > 1 ? 's' : ''}.`
    });
    if (!confirm) {
      return;
    }
    await deleteSelectedComments();
    loadArticleComments();
    if (reportedContent !== undefined) {
      queryClient.invalidateQueries({ queryKey: ['reportedContent', magazine, articleId] });
      queryClient.invalidateQueries(['reportedContents', magazine]);
    }
  }

  const deleteSelectedComments = async () => {
    try {
      setDeleteLoading(true);
      await API.comments.deleteComments(magazine, selected);
      setSelected([]);
    } catch (error) {
      EdErrorHandler(error, `deleting selected comments.`)
    } finally {
      setDeleteLoading(false);
    }
  }


  return (
    <div className={styles.ArticleDiscussion}>
      {loading || deleteLoading ? <OrtecLoader /> :
        <>
          {(helpText || helpTextNotFound) &&
            <div className={helpTextClass} style={{ marginBottom: '20px' }}>{noCommentsFound ? helpTextNotFound : helpText}</div>}
          <div className={styles.commentsContainer}>
            {_.map(findParentComments(comments), (c) => {
              return <ArticleDiscussionComment key={c.id} comment={c} replies={findRepliesOfComment(comments, _.toNumber(c.id))} selected={selected} onToggle={onCommentToggle} activeCommentId={activeCommentId} readOnly={readOnly} changeActiveCommentId={changeActiveCommentId} reportedContent={reportedContent} />
            })}
          </div>
          {!_.isEmpty(selected) && <div className={styles.deleteButtonWrapper}><Button onClick={onDeleteSelectedComments}>Delete selected comments</Button></div>}
        </>
      }
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const findParentComments = (comments: Comment[]): Comment[] => {
  return _.filter(comments, (c) => !c.parentId);
}

const findRepliesOfComment = (comments: Comment[], commentId: number): Comment[] => {
  return _.filter(comments, (c) => c.parentId == commentId);
}